import styled from '@emotion/styled';

const PrivacyPolicyPageStyle = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 145px 0 93px;

  .content-wrapper {
    max-width: 917px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 661px;
      margin-left: -28%;

      .title {
        font-size: 60px;
        line-height: 64px;
        color: #fbca00;
        font-family: RubikMedium;
        padding-bottom: 31px;
      }
      .subtitle {
        margin-top: 32px;
        font-size: 20px;
        line-height: 32px;
        color: #1d2332;
        font-family: RubikMedium;
      }
      .note {
        padding-right: 30px;
      }
      p {
        font-size: 15px;
        line-height: 20px;
        color: #a5a7ad;
        font-family: RubikRegular;

        span {
          color: #1d2332;
          font-size: 15px;
          line-height: 20px;
          font-family: RubikMedium;
        }
      }
      a {
        font-size: 15px;
        line-height: 20px;
        font-family: RubikRegular;
        margin-top: 0px;
        word-break: break-all;
      }
      li {
        font-size: 15px;
        line-height: 20px;
        font-family: RubikRegular;
        color: #a5a7ad;
      }
      table {
        border-spacing: 0;
        order-collapse: collapse;
        margin-right: auto;

        .c7 {
          // border-right-style: solid;
          padding: 3pt 3pt 3pt 3pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          border-top-color: #000000;
          // border-bottom-style: solid;

          p {
            line-height: 1.0;
          }
        }
        .c10 {
          border-right-style: solid;
          padding: 3pt 3pt 3pt 3pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          border-top-color: #000000;
          // border-bottom-style: solid;

          p {
            line-height: 1.0;
          }
        }
        .c11 {
          // border-right-style: solid;
          padding: 3pt 3pt 3pt 3pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          border-top-color: #000000;
          // border-bottom-style: solid;

          p {
            line-height: 1.0;
          }
        }
        .c27 {
          background-color: #d0d0d0;
        }
        .c29 {
          border-right-style: solid;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          border-top-color: #000000;
          // border-bottom-style: solid;
        }
        .c31 {
          height: 17.2pt;
        }
        .c37 {
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          border-top-color: #000000;
          border-bottom-style: solid
        }
        .c40 {
          height: 25.5pt;
        }
        .c41 {
          border-bottom-style: solid;
        }
        td {
          line-height: auto;

          p {
            margin-left: 5pt;
            padding-top: 0pt;
            padding-bottom: 0pt;
            orphans: 2;
            widows: 2;
            text-align: left;
            font-size: 13px;
            line-height: 0;

            span {
              font-size: 13px;
              line-height: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1120px) {
    .content-wrapper {
      .content {
        margin-left: 0%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content-wrapper {
      justify-content: flex-start;
      align-items: flex-start;

      .content {
        width: 100%;
        margin-left: 0%;
        padding-left: 30px;
        padding-right: 30px;
        max-width: 521px;

        .title {
          font-size: 30px;
          padding-bottom: 16px;
        }

        .note {
          padding-right: 0px;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .content-wrapper {
      .content {
        margin-left: 0%;
        padding-left: 30px;
        padding-right: 30px;

        .title {
          font-size: 30px;
          padding-bottom: 16px;
        }

        .note {
          padding-right: 0px;
        }

        table {
          width: 320px;
          table-layout: fixed;

          .c7 {
            padding: 0;
          }

          .c10 {
            padding: 0;
          }

          .c11 {
            padding: 0;
          }

          .c29 {
            padding: 0;
          }

          .c37 {
            padding: 0;
          }

          td {
            p {
              margin-left: 2pt;
              word-break: break-word;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 320px) {
    .content-wrapper {
      .content {
        margin-left: 0%;
        padding-left: 30px;
        padding-right: 30px;

        .title {
          font-size: 30px;
          padding-bottom: 16px;
        }

        .note {
          padding-right: 0px;
        }

        table {
          width: 285px;
          table-layout: fixed;

          .c7 {
            padding: 0;
          }

          .c10 {
            padding: 0;
          }

          .c11 {
            padding: 0;
          }

          .c29 {
            padding: 0;
          }

          .c37 {
            padding: 0;
          }

          td {
            p {
              margin-left: 2pt;
              word-break: break-word;
            }
          }
        }
      }
    }
  }
`;

export { PrivacyPolicyPageStyle };


