import React, { FunctionComponent } from 'react';

import { PrivacyPolicyPageStyle } from './PrivacyPolicyPageStyle';
import Layout from '../../layouts/Layout';
import { Helmet } from 'react-helmet';

const PrivacyPolicyPage: FunctionComponent<{}> = () => (
  <Layout isFAQPage>
    <PrivacyPolicyPageStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
      </Helmet>
      <div className="content-wrapper">
        <div className="content">
          <div className="title">Privacy Policy</div>
          <div className="note">
            <p>
              Hello, welcome to the Travelpix Privacy Policy. At Travelpix, we are committed to keeping your
              personal information safe and secure, and handling it in accordance with our legal obligations. This Privacy
              Policy sets out in detail the purposes for which we process your personal information, what rights you have in
              relation to that information, who we share it with and everything else we think is important for you to be aware
              of.
            </p>
            <p>
              Please make sure you check it carefully and if you do not agree with it, then (although we hate to
              turn you away) you should not use our digital products or services. This is because by accessing our services, you
              confirm that you accept the way in which we process your personal information. This Privacy Policy forms part of
              our&nbsp;
                <a href="/terms-and-conditions">Terms</a>
              &nbsp;and capitalised words and phrases in it have the same meaning as those in our Terms.
            </p>
            <p>
              If you have any concerns, please feel free to contact us at:&nbsp;
                <a target="_blank" href="mailto:team@travelpix.io">team@travelpix.io</a>
            </p>
          </div>

          <div className="subtitle">About TravelPix</div>
          <div className="note">
            <p>
              Better Photos Limited, trading as Travelpix (referred to as Travelpix, we, our or us), is a limited
              company incorporated in England and Wales, with registered company number 12411722 and registered at 72 Redcliffe
              Close, Old Brompton Road, London, SW5 9HZ. We are the data controller for the purposes of the personal information
              processed in accordance with this Privacy Policy.
            </p>
            <p>
              Our Data Protection Officer is responsible for our approach to data protection and protecting your
              privacy. You can contact our Data Protection Officer via &nbsp;
                <a target="_blank" href="mailto:team@travelpix.io">team@travelpix.io</a>
            </p>
          </div>

          <div className="subtitle">Contents of this Privacy Policy:</div>
          <div className="note">
            <ol>
              <li>
                About this Privacy Policy
              </li>
              <li>
                The personal information we may collect, how we would collect it, and why
              </li>
              <li>
                Our legal basis for processing personal information
              </li>
              <li>
                When do we share your personal information?
              </li>
              <li>
                Communications
              </li>
              <li>
                How long do we store your personal information?
              </li>
              <li>
                Security of your personal information
              </li>
              <li>
                Links
              </li>
              <li>
                Age restrictions
              </li>
              <li>
                Your rights and choices
              </li>
              <li>
                Contacting us
              </li>
              <li>
                Browser and device local storage
              </li>
            </ol>
          </div>

          <div className="subtitle">1. About this Privacy Policy</div>
          <div className="note">
            <p>
              This Privacy Policy applies to the personal information we collect about you through our services,
              by telephone, by post, through our social media platforms, from third parties and when you otherwise communicate
              with us.
            </p>
            <p>
              This Privacy Policy may change from time to time and, if it does, the up-to-date version will
              always be available on our Website.
            </p>
          </div>

          <div className="subtitle">2. The personal information we may collect, how we would collect it, and why</div>
          <div className="note">
            <p>
              This section informs you of what information we may collect about you and why.
            </p>
            <p>
              Personal information means any information about an individual from which that individual can be
              identified. The following shows information we may process about you, and the purpose for which we would process
              that information. There may be more than one reason for which we would collect such information and we have only
              listed the main reasons. If you would like further information, please contact us via&nbsp;
            <a target="_blank" href="mailto:team@travelpix.io">team@travelpix.io</a>
            </p>
            <p>
              We have also included the legal basis on which we rely to legitimately process your personal
              information.
            </p>
            <table>
              <tbody>
                <tr className="c40">
                  <td className="c11" colSpan={1} rowSpan={1}>
                    <p><span>Information</span></p>
                  </td>
                  <td className="c7" colSpan={1} rowSpan={1}>
                    <p><span>The main reason we would collect it</span></p>
                  </td>
                  <td className="c10" colSpan={1} rowSpan={1}>
                    <p><span>Legal basis for processing</span></p>
                  </td>
                </tr>
                <tr className="c31">
                  <td className="c29 c27" colSpan={3} rowSpan={1}>
                    <p><span>Information you provide to us</span></p>
                  </td>
                </tr>
                <tr className="c39">
                  <td className="c11" colSpan={1} rowSpan={1}>
                    <p>Name</p>
                  </td>
                  <td className="c7" colSpan={1} rowSpan={1}>
                    <p>
                      Your name enables us to personally communicate with you, both within our platform, and
                      externally in emails, SMS messages and other forms of communication.
                    </p>
                  </td>
                  <td className="c10" colSpan={1} rowSpan={1}>
                    <p>Performance of a contract with you.</p>
                  </td>
                </tr>
                <tr className="c23">
                  <td className="c11" colSpan={1} rowSpan={1}>
                    <p>Email address</p>
                    <p>Phone number</p>
                  </td>
                  <td className="c7" colSpan={1} rowSpan={1}>
                    <p>
                      These are unique identifiers for sign up and allow us to personally communicate with you
                      (e.g. sending marketing&nbsp;communications, service updates and sending &nbsp;customer satisfaction surveys). Each one is always stored privately against your
                      profile, so that only you and us can see it.
                    </p>
                  </td>
                  <td className="c10" colSpan={1} rowSpan={1}>
                    <p>Performance of a contract with you.</p>
                  </td>
                </tr>
                <tr className="c28">
                  <td className="c11" colSpan={1} rowSpan={1}>
                    <p>
                      City and country of residence&nbsp;<br></br>Address&nbsp;or postal code
                    </p>
                  </td>
                  <td className="c7" colSpan={1} rowSpan={1}>
                    <p>
                      To customise your experience and to ensure your browser and App settings are&nbsp;appropriately localised. Your address or&nbsp;postal code is always kept private.
                    </p>
                  </td>
                  <td className="c10" colSpan={1} rowSpan={1}>
                    <p>Performance of a contract with you.</p>
                  </td>
                </tr>
                <tr className="c39">
                  <td className="c11" colSpan={1} rowSpan={1}>
                    <p>Date of birth
                    </p>
                  </td>
                  <td className="c7" colSpan={1} rowSpan={1}>
                    <p>
                      To confirm that you are old enough to access our platform and consent to the processing of
                      your personal information. We keep this information private.
                    </p>
                  </td>
                  <td className="c10" colSpan={1} rowSpan={1}>
                    <p>Performance of a contract with you.</p>
                    <p></p>
                  </td>
                </tr>
                <tr className="c22">
                  <td className="c11" colSpan={1} rowSpan={1}>
                    <p>Gender</p>
                  </td>
                  <td className="c7" colSpan={1} rowSpan={1}>
                    <p>To populate your personal profile.</p>
                  </td>
                  <td className="c10" colSpan={1} rowSpan={1}>
                    <p>
                      Necessary for our legitimate interests (to study how users use our services, to develop
                      them and grow our business).
                    </p>
                  </td>
                </tr>
                <tr className="c35">
                  <td className="c11" colSpan={1} rowSpan={1}>
                    <p>
                      Your preferences for receiving communications and notifications
                    </p>
                  </td>
                  <td className="c7" colSpan={1} rowSpan={1}>
                    <p>
                      We may store your preferences so we know exactly how to communicate with you (e.g. for
                      marketing or sending service communications), and in some cases, how not to communicate with you.
                    </p>
                  </td>
                  <td className="c10" colSpan={1} rowSpan={1}>
                    <p>Consent; and Necessary for our legitimate interests (to ensure that we are not at risk of breaching data
                    protection laws by communicating with you where you have asked us not to).
                    </p>
                  </td>
                </tr>
                <tr className="c31">
                  <td className="c27 c29" colSpan={3} rowSpan={1}>
                    <p><span>Information we collect automatically</span>
                    </p>
                  </td>
                </tr>
                <tr className="c34">
                  <td className="c11" colSpan={1} rowSpan={1}>
                    <p>Your interactions with our service</p>
                    <p></p>
                  </td>
                  <td className="c7" colSpan={1} rowSpan={1}>
                    <p>
                      When you interact with our website or app, we record and track this information, so that we
                      can analyse how our users are enjoying our service and use that information to create improvements.
                    </p>
                  </td>
                  <td className="c10" colSpan={1} rowSpan={1}>
                    <p>
                      Necessary for our legitimate interests (to define types of users for our services, to keep
                      the platform updated and relevant, to study how users use our services, to develop our business, to
                      improve our website and app for our users and to inform our marketing strategy).
                    </p>
                  </td>
                </tr>
                <tr className="c32">
                  <td className="c11" colSpan={1} rowSpan={1}>
                    <p>Location tracker</p>
                  </td>
                  <td className="c7" colSpan={1} rowSpan={1}>
                    <p>
                      When you interact with our website or app, we will be able to determine your location which
                      will allow you to know when our photographers are near you and will allow you to explore locations that
                      are relevant to your current location when using the app.
                    </p>
                  </td>
                  <td className="c10" colSpan={1} rowSpan={1}>
                    <p>
                      Necessary for our legitimate interests (to tailor our service offering to where our users
                      are located when using the app which will allow us to learn which locations are most popular for our users
                      and will allow us to improve our service, by knowing where users are using the app).
                    </p>
                  </td>
                </tr>
                <tr className="c17">
                  <td className="c11 c41" colSpan={1} rowSpan={1}>
                    <p>IP address</p>
                  </td>
                  <td className="c7 c41" colSpan={1} rowSpan={1}>
                    <p>
                      This enables us to uniquely identify you as a user and to distinguish you from other users.
                      In turn, it enables us to deliver you a more personalised service.
                    </p>
                  </td>
                  <td className="c10 c41" colSpan={1} rowSpan={1}>
                    <p>
                      Necessary for our legitimate interests (to define types of customers for our platform, to
                      keep our platform updated and relevant, to develop our business and to inform our marketing
                      strategy).
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              We may also use all of the above information to establish, exercise and defend our legal rights.
              The lawful bases on which we rely upon to do so are where it is necessary for compliance with a legal obligation and where it is in our
              legitimate interest to establish, exercise or defend our legal rights.
            </p>
            <p>
              In respect of all the above information, our overarching purpose is to enable us to generate a
              trusted, secure and engaged community. We want all of our users&rsquo; information to be secure, but also visible
              to us so that we can provide them with a personalised customer service experience. For any questions, please feel
              free to get in touch via the email address above.
            </p>
          </div>

          <div className="subtitle">3. Our legal basis for processing personal information</div>
          <div className="note">
            <p>
              We only ever use your information in line with applicable data protection laws - in particular, the
              EU General Data Protection Regulation (GDPR). In short, this means we only use it where we have a legal basis to
              do so. Under GDPR, these are the general legal bases for which we process your personal information, as detailed
              in the table above:
            </p>
            <ul>
              <li>
                <p>
                  <span>Consent</span>
                  &nbsp;- you have given us consent to process your personal information for a specific purpose
                  that we have told you about.
                </p>
              </li>
              <li>
                <p>
                  <span>Performance of our contract</span>
                &nbsp;- processing your personal information is necessary for a contract you have with us, or
        because we have asked you to take specific steps before entering into that contract.
                </p>

              </li>
              <li>
                <p>
                  <span>Legitimate interests</span>
                  &nbsp;- processing your personal information is necessary for our legitimate interests or those
                  of a third party, provided those interests are not outweighed by your rights and interests (including where
        processing is required to comply with or enforce a legal obligation).
                </p>
              </li>
            </ul>
          </div>

          <div className="subtitle">4. When do we share your personal information?</div>
          <div className="note">
            <p>
              We may disclose your information for certain purposes and to third parties, as described
              below:
            </p>
            <ul>
              <li>
                The Travelpix group of companies: we share your information within the Travelpix group of
                companies as required for: providing you with access to our services according to our agreement, data storage
                and processing, providing customer support, making internal choices around business improvements, content
                development, and for the other purposes set out in this Privacy Policy.
              </li>
            </ul>
            <ul>
              <li>
                Third Party Providers: We use certain companies, agents or contractors (Third Party Providers) to
                perform services on our behalf or to help deliver our services to you including but not limited to Google
                Analytics and to Stripe (who will administer all payment related matters on behalf of Travelpix). We contract
                with Third Party Providers, for example to provide advertising, marketing, communications, infrastructure and IT
                services, to personalise and enhance our services, to provide customer service, to collect debts, to analyse and
                action data (including data about our users interactions with our platform), and to process and administer
                consumer surveys. In the course of providing such services, these Third Party Providers may have access to your
                personal information. We do not authorise them to use or disclose your personal information except in connection
                with providing their services to us.
              </li>
            </ul>
            <ul>
              <li>
                To protect legitimate interests: There are certain circumstances where Travelpix and our Third
                Party Providers may disclose and/or make use of your information where a disclosure would be necessary to: (a)
                satisfy any applicable law, regulation, legal process, or other legal or governmental request or requirement,
                (b) enforce applicable terms of use, including investigation of any actual or alleged breaches, (c) detect,
                prevent, or otherwise address illegal or suspected illegal activities (including fraud), security or technical
                issues, or (d) protect against harm to the rights, property or safety of Travelpix, its members or the public,
                as required or permitted by law.
              </li>
            </ul>
            <ul>
              <li>
                Transfers of our business: In connection with any corporate reorganisation, restructuring, merger
                or sale, or other transfer of assets, we will transfer information, including personal information, provided
                that the receiving party agrees to comply with our requirements as set out in this Privacy Policy relating to
                your personal information.
              </li>
            </ul>
          </div>

          <div className="subtitle">5. Communications</div>
          <div className="note">
            <p>
              This section is to explain how we will ensure that you only receive communications that you wish to
              receive.
            </p>
            <p>
              <span>Marketing communications</span>
            </p>
            <p>
              We want to ensure that you are informed and aware of the best services and promotions that we can
              offer you. By consenting to receive additional communications (by push notifications, mail, telephone, text/picture/video message or email) from us and any named third parties that feature at the point of obtaining consent in respect
              of such information, we will process your personal information in accordance with this Privacy Policy.
            </p>
            <p>
              You can change your marketing preferences and unsubscribe at any time by accessing the settings
              within our platform. If you choose not to receive this information we will be unable to keep you informed of new
              services and promotions of ours, or the Travelpix group of companies, that may interest you.
          </p>
            <p>
              Whatever you choose, you will still receive other important information, for example service
              updates, as described below.
          </p>
            <p>
              <span>Service communications</span>
            </p>
            <p>
              As detailed in the table at section 2 above, we may send you communications such as those which
              relate to any service updates (e.g. service availability) or provide customer satisfaction surveys. We consider
              that we can lawfully send these communications to you as we have a legitimate interest to do so, namely to
              effectively provide you with the best service we can and to grow our business.
            </p>
          </div>

          <div className="subtitle">6. How long do we store your personal information?</div>
          <div className="note">
            <p>
              This section explains the length of time that we will retain your personal information.
            </p>
            <p>
              We keep your personal information for only as long as is necessary to provide you with our service
              and for our legitimate and necessary business purposes. Such purposes might include maintaining the high standards
              of service which we strive to uphold, making decisions on how to progress our offering, complying with applicable
              legal obligations, and resolving any disputes which arise in the course of our business.
            </p>
            <p>
              In accordance with this Privacy Policy, you have the right to request that we delete your personal
              information, except where we are legally permitted or required to maintain certain personal information. For
              example:
            </p>
            <ul>
              <li >
                If there is an unresolved issue relating to your account, for example relating to outstanding an
                unresolved dispute, then we will retain your personal information until the issue is resolved.
              </li>
            </ul>
            <ul>
              <li>
                There may be other situations where we have legitimate business interests to retain personal
                information, such as to prevent fraud or protect security of our other users.
              </li>
            </ul>
            <p>
              Any Third Party Providers that we engage will keep your personal information stored on their
              systems for as long as is necessary to provide the relevant services to you or us. If we end our relationship with
              any third party providers, we will make sure that they securely delete or return your personal information to
              us.
            <p>
                We may retain personal information about you for statistical purposes. Where information is
                retained for statistical purposes it will always be anonymised, meaning that you will not be identifiable from
                that information.
              </p>
            </p>
          </div>

          <div className="subtitle">7. Security of your personal information</div>
          <div className="note">
            <p>
              This section explains how we keep your personal information safe and where it will be held.
            </p>
            <p>
              We are committed to securing and protecting your personal information, and we make sure to
              implement appropriate technical and organisational measures to help protect the security of your personal
              information. We have implemented various policies including anonymisation, pseudonymisation, encryption, access,
              and retention policies to guard against unauthorised access and unnecessary retention of personal information in
              our systems.
            </p>
            <p>
              The information that we collect from you may be transferred to, and stored at, a destination
              outside of the European Economic Area (EEA). When we transfer and store your personal information outside of the
              EEA we will take steps to ensure that the information is transferred in accordance with this Privacy Policy and
              applicable data protection laws. In particular, we will ensure that appropriate contractual, technical, and
              organisational measures are in place with any parties outside the EEA such as the Standard Contractual Clauses
              approved by the EU Commission.
            </p>
            <p>
              Unfortunately, the transmission of your personal information via the internet is not completely
              secure and although we do our best to protect your personal information, we cannot guarantee the security of your
              information transmitted to us over the internet and you acknowledge that any transmission is at your own
              risk.
            </p>
          </div>

          <div className="subtitle">8. Links</div>
          <div className="note">
            <p>
              This section explains the risks of clicking on any link within our platform.
            </p>
            <p>
              Our platform may, from time to time, contain links to websites operated by third parties, which
              will usually be those of our commercial partners. This Privacy Policy only applies to the personal information
              that we collect from you and we cannot be responsible for personal information collected and stored by third
              parties. If you click on a link, please understand that the relevant third party websites have their own terms and
              conditions and privacy policies, and we do not accept any responsibility for the content of those third party
              websites or third party terms and conditions or policies. Please check these policies before you submit any
              personal information to these websites.
            </p>
          </div>

          <div className="subtitle">9. Age restrictions</div>
          <div className="note">
            <p>
              You must be 16 years of age or older to use Travelpix.
            </p>
            <p>
              If you are under 16 years of age, then you will be unable to use Travelpix.
            </p>
          </div>

          <div className="subtitle">10. Your rights and choices</div>
          <div className="note">
            <p>
              This section explains that you have a number of rights in relation to your personal
              information.
            </p>
            <p>
              Under GDPR, as a user of our platform, you are entitled to certain rights. There are circumstances
              in which your rights may not apply. You have the right to request that we:
            <ul>
                <li>
                  provide you with a copy of the information we hold about you;
                </li>
                <li>
                  update any of your personal information if it is inaccurate or out of date;
                </li>
                <li>
                  delete the personal information we hold about you - if we are providing services to you and you
                  ask us to delete personal information we hold about you then we may be unable to continue providing those
                  services to you;
                </li>
                <li>
                  restrict the way in which we process your personal information;
                </li>
                <li>
                  stop processing your data if you have valid objections to such processing; and
                </li>
                <li>
                  transfer your personal information to a third party.
                </li>
              </ul>
              <p>
                For more information on your rights and how to use them, or if you would like to make any of the
                requests set out above, please contact us via the email address above.
            </p>
              <p>
                We may need to request specific information from you to help us confirm your identity and ensure
                your right to access your personal information (or to exercise any of your other rights). This is a security
                measure to ensure that personal information is not disclosed to any person who has no right to receive it. We may
                also contact you to ask you for further information in relation to your request to speed up our response.
            </p>
              <p>
                As explained in the section on&nbsp; <strong>Communications</strong>, even if you consented to the processing of your personal information for marketing purposes (by
                ticking the relevant box or by requesting information about services), you have the right to ask us to stop
                processing your personal information for such purposes. You can exercise this right at any time by contacting us
                at team@travelpix.io or adjusting your privacy and notification settings within the platform. Please note
              that we reserve the right to charge a fee for responding to requests where we reasonably determine that they are
                manifestly unfounded or onerous or being made in bad faith.
            </p>
            </p>
          </div>

          <div className="subtitle">11. Browser and device local storage</div>
          <div className="note">
            <p>Our platform uses browser and device local storage files &ndash; the browser files are for if you
            are using the progressive web app and the device local storage files are if you are using the iOS or Android app.
            Browser local storage files are files containing small amounts of information which are downloaded to your
            personal computer, mobile or other device when you visit an app or website. In particular, our browser and device
            local storage files generate a unique identification number for each user which we use to collect certain
            information on that user.
            </p>
            <p>
              We use browser and device local storage to store data locally on your device to enhance the user
              experience on our platform. For example, browser and device local storage allows us to save the state of your
              current page so that when you return at a later time or refresh the browser or app, the page will remain the same.
              We may also use browser and device local storage to: (i) save a list of items a user last accessed on the platform
              (such as events and properties the user selected) so we can display &ldquo;recently viewed items&rdquo;; or (ii)
              marking once a user has seen a tutorial, video, tooltip or onboarding flow so that the user only sees the content
              one time.
            </p>
          </div>

          <div className="subtitle">12. Contacting us</div>
          <div className="note">
            <p>
              If you have any questions or concerns about how we handle your personal information, please contact
              our Data Protection Officer (DPO), by email at&nbsp;
              <a target="_blank" href="mailto:raphael@travelpix.io">raphael@travelpix.io</a>.
            </p>
            <p>
              If you are unsatisfied with our response to any data protection issues you raise with us or our
              DPO, you have the right to make a complaint to the&nbsp;
              <a target="_blank" href="https://www.google.com/url?q=https://ico.org.uk/concerns/&amp;sa=D&amp;ust=1607935234396000&amp;usg=AOvVaw3NDjTusfr-UStuuZPVzMig">Information
              Commissioner&rsquo;s Office (ICO)</a>. The ICO is the authority in the UK which is tasked with the protection of personal information
              and privacy. &nbsp;
            </p>
          </div>
        </div>
      </div>
    </PrivacyPolicyPageStyle>
  </Layout >
);

export default PrivacyPolicyPage;
